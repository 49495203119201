.video{
  width: 100%;
  display: grid;
  margin-bottom: 60px;
}
.videoInvisible{
  opacity: 0;
  transition: var(--transitionCorta);
  position: relative;
  bottom: -100px;
  user-select: none;
}
.videoVisible{
  opacity: 1;
  transition: var(--transitionCorta);
  position: relative;
  bottom: 0;
  user-select: none;
}
@media (min-width:800px) {
  .video{
    display: none;
  }
}