.containerLeft,.containerRight{
  margin: 20px;
  overflow: hidden;
  display: grid;
  position: relative;
}
.desktop{
  display: none;
}
.imagenLeft,.imagenRight{
  width: 85%;
  height: 170px;
  overflow: hidden;
  display: grid;
  align-content: center;
}
.imagenRight{
  justify-self: right;
}
.imagenLeft img, .imagenRight img{
  width: 100%;
  position: relative;
}
.subCardLeft,.subCardRight{
  background-color: white;
  width: 65%;
  height: 145px;
  justify-self: right;
  position: relative;
  top: -65px;
  padding: 20px 20px 20px 25px;
  display: grid;
  cursor: pointer;
}
.subCardRight{
  justify-self: left;
}

.tittle{
  color: var(--rojo);
  font-size: 15pt;
  font-family: 'ExtraBold';
  height: fit-content;
}
.subTittle{
  font-size: 15pt;
  font-family: 'Bold';
  margin-top: 5px;
}
.textRuta{
  font-size: 8pt;
  font-family: 'Regular';
  letter-spacing: 2px;
  display: inline-block;
}
.flecha{
  display: flex;
  gap: 20px;
  align-items: flex-end;
  transition: .4s;
}
.flecha svg{
  font-size: 8pt;
  position: relative;
  top: -1.2px;
}
.subCardLeft:hover .flecha{
  gap: 10px;

}
.subCardRight:hover .flecha {
gap: 10px;
}
.visibleRight{
  opacity: 1;
  transition: var(--transitionCorta);
  position: relative;
  right: 0;
}
.visibleLeft{
  opacity: 1;
  transition: var(--transitionCorta);
  position: relative;
  left: 0;

}
.invisibleLeft{
  position: relative;
  transition: var(--transitionCorta);
  opacity: 0;
  left: -100px;
}
.invisibleRight{
  position: relative;
  transition: var(--transitionCorta);
  opacity: 0;
  right: -100px;
}



@media (min-width: 200px) and (max-width: 390px){
  .subCardLeft,.subCardRight{
    height: 170px;
  }
}
@media (min-width: 390px) and (max-width: 408px){
  .subCardLeft,.subCardRight{
    height: 145px;
  }
}
@media (min-width: 409px) and (max-width: 800px){
  .subCardLeft,.subCardRight{
    height: 125px;
  }
}
@media (min-width:800px) {
  .mobile{
    display: none;
  }
  .desktop{
    background-size: cover;
    background-position: center;
    background-position-x:-60px ;
    margin: 20px;
    background-repeat: no-repeat;
    display: flex;
    height: 270px;
    align-items: center;
    justify-content: flex-end;
  }
  .subCardLeft,.subCardRight{
    top: 0;
    height: 130px;
    width: 230px;
  }
}
