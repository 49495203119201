
.headerImg{
  padding: 0 20px 0 20px;
  width: calc(100% - 40px);
}
.title{
  background-color: white;
  width: 60%;
  margin: auto;
  padding: 25px;
  position: relative;
  top: -40px;
  display: grid;
  gap: 10px;
  justify-items: center;
}
.title h3{
  line-height: 23px;
  font-family: 'ExtraBold';
  font-size: 15pt;
  width: fit-content;
  text-align: center;
  color: var(--rojo);
}
.verReceta{
  display: flex;
  align-items: center;
  gap: 20%;
  font-family: 'Regular' ;
  letter-spacing: 2px;
}

@media (min-width:800px) {
  .headerImg{
    padding: 0;
    width: 100%;
  }
  .headerDesktop{
    margin: var(--marginDesktop);
    height: 488px;
    overflow: hidden;
  }
  .title h3{
    font-size:36px;
    line-height: 40px;
    text-align: left;
  }
  .title{
    width: 400px;
    padding: 20px 80px 20px 40px;
    height: 150px;
    display: grid;
    align-items: center;
    top: -75px;
    margin: var(--marginCatalogo);
  }
}