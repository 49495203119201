.containerMobile{padding: 10px;}

.img{width: 100%;}

.Invisible{
    opacity: 0;
    transition: var(--transitionCorta);
    position: relative;
    user-select: none;
  }
  .Visible{
    opacity: 1;
    transition: var(--transitionCorta);
    position: relative;
    user-select: none;
  }
  .containerDesktop{
    display: none;
  }
@media (min-width:800px) {
  .containerMobile{
    display: none;
  }
  .containerDesktop{
    display: block;
  }
    .containerDesktop{
        padding: 0;
    }
    .imagenContainer{
        overflow: hidden;
    }
}