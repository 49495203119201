.fondo{
  height: 0;
}
.fondo img{
  width: 100%;
}
.imgContainer{
  width: 100%;
  overflow: hidden;
}
.Home{
  overflow-x: hidden;
}

.top{
  background-repeat: no-repeat;
  padding: 60px 20px 0 20px;
  background-size: auto 100% ;
  background-position: center;
  height: 150px;

}
.tittle{
  width: 60%;
  color: var(--rojo);
  font-size: 18pt;
  letter-spacing: 3px;

}
.headerCarusellDesktop{
  display: none;
}
.subTittle{
  width: 70%;
  font-size: 10pt;
  margin-top: 13px;
  font-family: 'Bold';
  color: black;
}
.aclaracion{
  font-size: 8pt;
  margin-top: 3px;
}
.boton{
  margin-top: 20px;
  padding-bottom: 30px;
  width: fit-content;
}
.carrusell{
  margin-top: 30px;
}
.carrusell,.carrusell div{
  z-index: 1;
  
}
.carrusell li{
  width: fit-content ;
}
.video{
  width: 100%;
  display: grid;
}
.video iframe{
  height: 300px;
  margin: auto;
}
.plus{
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}
.plus img{
  width: 50px;
  height: 50px;
}

.produDestacados{
  margin-top: 70px;

}

.productosDesktop{
  display: none;
}
.videoDesktop{
  display: none;
}
.catalogCarrusellDesktop{
  display: none;
}
@media (min-width:486px)  {
  .top{
    background-size:   100% auto; 
  }
}
@media (min-width:800px) {
  .headerCarusellDesktop{
    display: block;
  }
  .headerCarusellMobile{
    display: none;
  }
  .catalogCarrusell{
    display: none;
  }
  .headerCarusell{
    user-select: none;
  }
  .overflow{
    overflow: hidden;
    display: grid;
    justify-content: center;
  }
  .catalogCarrusellDesktop{
    display: block;
    width: 700px
  
  }
  .catalogCarrusellDesktop div{
    overflow: visible;
  } 
  .top{
    transition: var(--transitionCorta);
    background-position: center;
    padding: 80px 10% 0% 10%;
    height: 488px;
    overflow-y: visible;
    display: grid;
    align-content: center;
    background-size: auto 568px ;
  }
  .tittle{
    font-size: 35pt;
  }
  .subTittle{
    font-size: 15pt;
  }
  .aclaracion{
    font-size: 11pt;
    font-family: 'Regular';
  }
  .tittle,.subTittle,.aclaracion{
    width: 28vw;
  }
  .boton{
    margin-top: 4%;
  } 
  .carrusell{
    display: none;
  }
  .productosDesktop{
    display: flex;    
    justify-content: space-around;
    margin: var(--marginDesktop);
    margin-bottom: 70px;
    margin-top: 20px ;
  }
  .cards{
    display: grid;
    grid-template-columns: 50% 50%;
    margin: var(--marginDesktop);
  }
  .video{
    display: none;
  }
  .videoDesktop{
    display: flex;
    justify-content: center;
    margin: var(--marginDesktop);
    padding: 20px;
    height: 600px;
    margin-top: 100px;
  }
  .conocenos{
    transition: var(--transitionCorta);
    position: relative;
    left: 0vw;
    opacity: 1;
  }
  .carrusellInvisible{
    opacity: 0;
    transition: var(--transitionCorta);
    position: relative;
    bottom: -100px;
  }
  .carrusellVisible{
    opacity: 1;
    transition: var(--transitionCorta);
    position: relative;
    bottom: 0;
  }
  .visitar{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    text-decoration: none;
  }
  .visitar:hover{
    text-decoration: underline 2px solid var(--grisFuente);
    text-underline-offset: 2px;
  }
  .visitarPerfil{
    height: 28px;
  }
}



.contenedor{
  display: flex;
  width: 100vw;
  padding: 0 10px 0 10px ;
  gap: 5px;
}
.card{
  width: calc(100%);
}