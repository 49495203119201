.right{
  padding: 10px;
  cursor: pointer;
  display: grid;
}
.img{
  width: 100%;
  max-width: 400px;
  margin: 90px 0 90px 0;
  filter: var(--centerShadow);
}
.title{
  font-size: 11.5pt;
  margin-bottom: 2px;
}
.subTitle{
  font-size: 11pt;
}