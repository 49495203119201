.container{
  background-color: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top:0;
  left: 0;
  width: 5vw;
  font-size: 100px;
  cursor: pointer;
  pointer-events:fill;
  opacity: 0;
  transition: var(--transitionCorta);
}

.container:hover{
  pointer-events: all;
  opacity: .5;

}