*{
  margin: 0;
  padding: 0;
}

.CambiarIdioma ,.CambiarIdiomaActivo{
  font-family: 'Mulish', sans-serif;
  cursor: pointer;
  transition: var(--transitionCorta);

}
.CambiarIdiomaActivo{
  transition: var(--transitionCorta);
  color: white;
}

.CambiarIdioma svg,.CambiarIdiomaActivo svg{
  position: relative;
  top: 1px;
  margin: 0 0 0 3px;
  cursor: pointer;
}
.activo{
  display: grid;
  align-items: center;
}
.opcionNegra,.opcionBlanca{
  display: block;
  position: fixed;
  color: var(--grisFuente);
  transition: var(--transitionCorta);
}
.blanco,.negro,.blanco path,.negro path,.opcionNegra path{
  transition: var(--transitionCorta);
}
.blanco,.negro{
  white-space: nowrap;
}
.blanco,.opcionBlanca path,.blanco path ,.opcionBlanca{
  color: white;
}

