.requiredContainer{
  padding: 20px;
  padding-top:60px;
}

.container{
  height: 450px;
  background-color: white;
  width: fit-content;
  overflow: visible;
  display: grid;
}

.top{
  width: 100%;
  overflow: visible;
  display: flex;
  justify-content: center;
  
}

.img{
  filter: var(--shadow);
  position: relative;
  width: 70%;
  top: -60px;
}
.bottom{
  display: grid;
  padding: 20px;
  gap: 10px;
  margin-top: -20px;
  align-self: end;
}

.flecha{
  display: flex;
  gap: 20px;
  width: fit-content;
  align-items: center;
  cursor: pointer;
}
.flecha svg{
  transition: var(--transitionCorta);
}
.flecha:hover svg{
  transform: translateX(-10px);
}
.flecha svg{
  font-size: 8pt;
  position: relative;
  top: 1px;
}
.subTittle{
  font-size: 12pt;
  width: 80%;
  color: var(--rojo);
}
.textRuta{
  font-size: 10pt;
  display: inline-block;
}
.Linea{
  position: relative;
}
@media (min-width:550px) {
  .container{
    height: 550px;
  }
}
@media (min-width:800px) {
  .requiredContainer{
    padding: 50px;
    display: inline-flex;
    width: 600px;
  }
  .container{
    display: grid;
    width: fit-content;
    grid-template-columns: 50% 50%;
    height: 350px;
  }
  .top{
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
  }

  .img{
    width: 120%;
    height: 120%;
    left: -20px;
    top: 0;
  }
  .bottom{
    gap: 0;
    align-self: center;
    width: fit-content;
    padding-right: 60px;
  }
  .tittle{
    width: fit-content;
  }
  .subTittle{
    margin-top: 10px;
  }
  .flecha{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 30px;
    width: 100%;
  }
}
