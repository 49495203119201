.construccionContainer{
  z-index: 900000000000;
  background-color:#EEECEA ;
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cartelMedio{
  background-color: #D1D2D5;
  padding: 50px;
  font-size: 25pt;
  font-family: 'ExtraBold';
  border-radius: 10px;
  display: grid;
  align-content: center;
  justify-items: center;
  gap: 20px;
}
.logo{
  width: 40%;
}