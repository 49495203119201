:root{
  --grisFondo: #EEECEA;
  --grisMedio:#D3D3D3;
  --marronHeader:#D9D4D0;
  --grisFuente:#4E4E4E;
  --grisNumerosFiltros:#A7A7A7;
  --grisSeparadores:#D2D2D2;
  --grisCheck:#C4C4C4;
  --transitionCorta:  .5s;
  --rojo: #E2091D;
  --rojoBajo: #7e0e19;
  --naranjaMasterChef:#E26340;
  --gradient:linear-gradient(var(--marronHeader) 60% , transparent 100% );
  --shadow:drop-shadow(25px 23px 10px rgba(0, 0, 0,.15));
  --centerShadow:drop-shadow(0px 0px 10px rgba(0, 0, 0,.15));
  --strongShadow : drop-shadow(0px 2px 3px rgba(0, 0, 0,.7));
  --marginDesktop: 0 10vw 0 10vw;
  --marginCatalogo:0 15vw 0 15vw;
  --marginIzquierda: 0 15vw 0 10vw;
  --marginDerecha: 0 10vw 0 15vw;
  --marginReceta: 0 20vw 0 20vw
}
@media (min-width:2000px) {
  :root{
    --marginDesktop: 0 25vw 0 25vw
  }
}
@media (min-width:1500px) {
  :root{
    --marginDesktop: 0 10vw 0 10vw
  }
}
*{
  color: var(--grisFuente);
  scroll-behavior: smooth;
  font-family: 'Regular';
}
@font-face {
  font-family: 'Black';
  src: url(../src/Fuentes/Mulish-Black.ttf);
}
@font-face {
  font-family: 'Medium';
  src: url(../src/Fuentes/Mulish-Medium.ttf);
}
@font-face {
  font-family: 'Regular';
  src: url(../src/Fuentes/Mulish-Regular.ttf);
}
@font-face {
  font-family: 'Light';
  src: url(../src/Fuentes/Mulish-Light.ttf);
}
@font-face {
  font-family: 'ExtraLight';
  src: url(../src/Fuentes/Mulish-ExtraLight.ttf);
}
@font-face {
  font-family: 'ExtraBold';
  src: url(../src/Fuentes/Mulish-ExtraBold.ttf);
}
@font-face {
  font-family: 'Bold';
  src: url(../src/Fuentes/Mulish-Bold.ttf);
}
@font-face {
  font-family: 'SemiBold';
  src: url(../src/Fuentes/Mulish-SemiBold.ttf);
}

iframe{
  border-width: 0px;
}
p{
  font-family: 'SemiBold';
}
h1{
  font-family: 'Black';
}
h2{
  font-family: 'ExtraBold';
}
h3{
  font-family: 'Medium';
}
h4{
  font-family:'Regular' ;
}
h5{
font-family: 'Light';
}
h6{
  font-family: 'ExtraLight';
}



.alice-carousel__dots{
  margin: 3vh 0 3vh 0; 
}
.alice-carousel__dots-item.__active{
  border: var(--rojo) solid 5px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px 0 5px;
  transition: var(--transitionCorta);
}
.alice-carousel__dots-item{
  margin: 0 5px 0 5px;
  cursor: pointer;
  border-radius: 50%;
  border: 5px solid var(--rojoBajo);
  transition: var(--transitionCorta);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: fit-content;
  background-color: var(--grisFondo);
  overflow-x: hidden;
}
html{
  width: 100%;
  height: fit-content;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

