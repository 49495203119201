.container{
  padding: 0 20px 0 20px;
}
.titles{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titles h3{
  color: var(--rojo);
}
.imgLeft,.imgRight{
  width: 100%;
  margin: 90px 0 90px 0;
  filter: var(--centerShadow);
}
.bottom{
  display: flex;
  grid-template-columns: 40% 40%;
  justify-content: space-between;
  gap: 20px;
  width: calc(100% + 40px);
  margin: 0 0 90px -20px;
}
.verMas{
  font-family: 'Light';
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}
.title{
  font-size: 11.5pt;
  margin-bottom: 2px;
}
.subTitle{
  font-size: 11pt;
}
.vender{
  width: 70%;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
}
.tiendas{
  width: fit-content;
  margin: auto;
}
.bottomDesktop{
  display: none;
}
.visible{
  opacity: 1;
  transition: var(--transitionCorta);
}
.invisible{
  transition: var(--transitionCorta);
  opacity: 0;
}
@media (min-width:800px) {
  .container{
    margin: var(--marginDesktop);
    padding: 0;
  }
  .bottomDesktop{
    display: inline-flex;
    margin-bottom: 0;
  }
  .bottomMobile{
    display: none;
  }
  .tiendaOnline{
    display: none;
  }
}