.containerActivo,.containerInActivo{
  position: fixed;
  background-color: white;
  width: 100%;
  margin-left: 20%;
  height: 100%;
  z-index: 2000;
  transition: var(--transitionCorta);
  transform: translateX(-20%);
  top: 0px;
  overflow-y: scroll;
}
.containerInActivo{
  transform: translateX(100%);
}
.bloque,.subBloque{
  background-color: var(--rojo);
  color: white;
  padding: 20px;
  display: flex;
  gap: 10px;
  font-family: 'ExtraLight';
  cursor: pointer;
}
.subBloque{
  background-color: white;
  color: var(--grisFuente);
  padding: 15px 30px 15px 20px;
  display: flex;
  justify-content: space-between;
}

.bloque svg{
  position: relative;
  font-size: 11pt;
}
.bloque path{
  color: white;

}
.separador{
  margin-top: 20px;
}
.tiendas{
  margin: 20px 20px 20px 20px;
  display: grid;
  gap: 5px;
}
.redes{
  display:  flex;
  align-items: center;
  gap: 10px;
}
.redes img{
  height: 10px;
  width: 10px;
}
@media (min-width: 550px) {
  .containerActivo,.containerInActivo{
    display: none;
  }
}