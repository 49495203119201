.containerVisible,.containerInvisible{
  position: fixed;
  background-color: rgba(210, 210, 210, .7);
  width: 100vw;
  height: 100vh;
  z-index: 100000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerInvisible{
  display: none;
}
.cuadroCentro{
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  background-color: var(--grisMedio);
  opacity: 1;
  z-index:100000000000000 ;
  padding: 70px;
}
.loader circle{
  color: var(--rojo);
}
.errorMessage{
  display: grid;
  justify-items: center;
}
.volverIntentar{
  color: var(--rojo);
  font-family: 'Bold';
  font-size: 15pt;
  cursor: pointer;
}
.volverIntentar:hover{
  text-decoration: underline solid 1px var(--rojo);
}
.titles{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.asterisco{
  color: var(--rojo);
}