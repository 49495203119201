.top{
  background-repeat: no-repeat;
  padding: 60px 20px 0 20px;
  background-size: auto 100% ;
  background-position: center;
  height: 150px;
}
.tittle{
  width: 90%;
  color: var(--rojo);
  font-size: 18pt;
  letter-spacing: 3px;
}
.subTittle{
  width: 70%;
  font-size: 10pt;
  margin-top: 13px;
  font-family: 'Bold';
  color: black;
}
.aclaracion{
  font-size: 8pt;
  margin-top: 3px;
}
.boton{
  margin-top: 10px;
  padding-bottom: 30px;
  width: fit-content;
}
.headerCarusellDesktop{
  display: none;
}
@media (min-width:486px)  {
  .top{
    background-size:   100% auto; 
  }
}
@media (min-width:800px)  {
  .headerCarusellMobile{
    display: none;
  }
  .top{
    transition: var(--transitionCorta);
    background-position: center;
    padding: 80px 10% 0% 10%;
    height: 488px;
    overflow-y: visible;
    display: grid;
    align-content: center;
    background-size: auto 568px ;
  }
  .tittle{
    font-size: 35pt;
  }
  .subTittle{
    font-size: 15pt;
  }
  .aclaracion{
    font-size: 11pt;
    font-family: 'Regular';
  }
  .tittle,.subTittle,.aclaracion{
    width: 28vw;
  }
  .boton{
    margin-top: 4%;
  } 
  .headerCarusellDesktop{
    display: block;
  }
}