.inspirado{
  padding: 20px 0 40px 0;
}

.titles{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 20px 0 20px;
}

.desktop{
  display: none;
}
.inspirado h3{color: var(--rojo);}

.imagenes{
  display: flex;
  justify-content: center;
  gap: 20px;
}
.visible{
  opacity: 1;
  transition: var(--transitionCorta);
}
.invisible{
  transition: var(--transitionCorta);
  opacity: 0;
}
@media (min-width:800px) {
  .mobile{
    display: none;
  }
  .desktop{
    display: flex;
    gap: 1%;
  }
  .titles{
    padding: 0;
  }
  .inspirado{
    margin: var(--marginDesktop);
  }
  .vacio{
    width: 100%;
  }
}