.NavBar, .container, .containerInactivo{
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  transition: var(--transitionCorta);
  width:100% ;
  margin: 0 20px 0 20px;
  z-index: 90;
}
.container, .containerInactivo{
  display: flex;
  position: fixed;
  align-items: center;
  margin: 0;
  background-color: rgba(238, 236, 234, .7);
}

.containerInactivo{
  margin: 0;
  background-color: black;
}
.logos{
  display: flex;
  cursor: pointer;
}
.logo, .logoActivo{
  width: 100px;
  transition: var(--transitionCorta);
  opacity: 0;
}

.logoActivo{
  position: fixed;
  opacity: 1;
}
.menu,.menuActivo{
  width: fit-content;
  height: fit-content;
  display: inline-flex;
  transition: var(--transitionCorta);
  cursor: pointer;
  align-items: center;
  margin-left: 10px;
}
.menuActivo svg, .menuActivo svg{
  transition: var(--transitionCorta);
  color: white;
}
.cambiarIdiomaDesktop{
  display: none;
}
.activo path,.inactivo path{
  transition: var(--transitionCorta);
}
.activo path{
  color: white;
}
.desktopLinks{
  display: none;
}
.desktopLink,.desktopLinkRojo,.desktopLinkActivo{
  font-family: 'ExtraBold';
  font-size: 12pt;
  transition: var(--transitionCorta);
}
.desktopLinkRojo{
  color: var(--rojo);
}
.desktopLinkActivo{
  color: white;
}
.cambiarIdiomaMobile{
  display: flex;
  align-items: center;
  width: 70%;
  gap: 20px;
  justify-content: space-around;
}
.desplegable{
  position: fixed;
  top: 80px;
  z-index: 200;
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: white;
  transition: var(--transitionCorta);
}
.Full{
  cursor: pointer;
  height: 80px;
  display: flex;
  align-items: center;
}
.linksLogos{
  display: none;
}
@media (min-width: 800px) {
  .container, .containerInactivo{
    height: 80px;
  }
  .linksLogosContainer{
    width: 0px;
    overflow: visible;
  }
  .NavBar{
    margin: var(--marginDesktop);
  }
  
  .menu,.menuActivo{
    display: none; 
  }
  .cambiarIdiomaMobile{
    display: none;
  }
  .cambiarIdiomaDesktop{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 35px;

  }

  .desktopLinks{
    justify-content: space-between;  
    display: flex;
    white-space: nowrap;
    width: 100%;
    gap: 5px;
  }
  
  .desktopLink,.desktopLinkRojo,.desktopLinkActivo{
    font-size: 8pt;
    padding: 0;
  }
  .desktopLink,.desktopLinkRojo,.desktopLinkActivo{
    font-size: 5.5pt;
  }
  .linkLogo,.linkLogoActivo{
    width: 13px;
    opacity: 0;
    position: absolute;
    transition: var(--transitionCorta);
  }
  .linkLogoActivo{
    opacity: 1;
  }
  .linksLogos{
    /* transition: var(--transitionCorta); */
    display: flex;
    width: fit-content;
    gap: 7px;
    margin: 0 10px 0 10px;
  }
  .cambiarIdiomaDesktop{
    margin-left: 10px;
    gap: 5px;
    font-size: 8pt;
  }
  .logos{
    margin-right: 10px;
  }
  .logo,.logoActivo{
    width: 90px;
  }
  .linkLogoImgContainer{
    width: 13px;
    height: 13px;
    padding-bottom: 0px;
  }
}


@media (min-width:870px) {
  .logo,.logoActivo{
    width: 120px;
  }
  .linksLogos{
    gap: 15px;
  }
  .linkLogoImgContainer{
    width: 15px;
    height: 15px;
    padding-bottom: 5px;
  }
  .linkLogo,.linkLogoActivo{
    width: 20px;
    transition: var(--transitionCorta);
    opacity: 0;
    position: absolute;
  }

  .linkLogoActivo{
    opacity: 1;
  }
}


@media (min-width:1124px) {
  .desktopLink,.desktopLinkRojo,.desktopLinkActivo{
    font-size: 8pt;
  }
  .cambiarIdiomaDesktop{
    gap: 10px;
    margin-left: 35px;
  }
  .logos{
    margin-right: 35px;
  }
  .logo,.logoActivo{
    width: 140px;
  }
  .cambiarIdiomaDesktop{
    font-size: 11pt;
  }
}


@media (min-width:1261px)  {
  .desktopLink,.desktopLinkRojo,.desktopLinkActivo{
    font-size: 10pt;
  }
  .cambiarIdiomaDesktop{
    font-size: 12pt;
  }
}
@media (min-width:1360px)  {
  .linksLogos{
    margin-left: 30px;
  }
  .linkLogo,.linkLogoActivo{
    width: 25px;
  }
  .linkLogoImgContainer{
    width: 25px;
    height: 25px;
    padding-bottom: 0;
  }

}