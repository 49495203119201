.overflow{
  overflow: hidden;
  display: grid;
  justify-content: center;
}
.catalogCarrusellDesktop{
  display: block;
  width: 700px

}
.catalogCarrusellDesktop div{
  overflow: visible;
} 
.carrusellInvisible{
  opacity: 0;
  transition: var(--transitionCorta);
  position: relative;
  bottom: -100px;
  user-select: none;
}
.carrusellVisible{
  opacity: 1;
  transition: var(--transitionCorta);
  position: relative;
  bottom: 0;
  user-select: none;
}
@media (max-width:800px) {
  .overflow{
    display: none;
  }
}