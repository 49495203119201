.items{
    display: none;
}
.visible{
    opacity: 1;
    transition: var(--transitionCorta);
}
.invisible{
    transition: var(--transitionCorta);
    opacity: 0;
}
@media (min-width:800px) {
    .carrusell{
        display: none;
    }
    .items{
        display: flex;
        margin: var(--marginDesktop);
        gap: 20px;
    }

}