.conocenos{
  background-size: cover;
  background-position-x:-60px ;
  height: 250px;
  display: flex;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: flex-end;
  margin: 50px 0 70px 0;
  width: 100vw;
}

.pastas{
  background-image: url(../../imagenes/FOTO\ NOSOTROS\ PASTAS.jpg);
}
.camarones{
  background-image: url(../../imagenes/FOTO\ NOSOTROS\ CAMARONES.jpg);
}
.fideos{
  background-image: url(../../imagenes/FOTO\ NOSOTROS\ FIDEOS.jpg);
}
.subCard{
  background-color: white;
  width:45%;
  height: 140px;
  padding: 12px 10px 12px 25px;
  margin-right: 20px;
  cursor: pointer;
 
}
.tituloConocer{
  color: var(--rojo);
  font-size: 14pt;
}
.subTitleConocer{
  font-size: 12pt;
  width: 100%;
  height: 60px;
  display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}
.textRuta{
  font-size: 8pt;
  display: inline-block;
}
.flecha{
  display: flex;
  gap: 20px;
  transition: var(--transitionCorta);
}
.flecha svg{
  font-size: 8pt;
  position: relative;
  top: 6px;
  margin-left: 15%;
}
.espacios{
  margin: 5px 0 5px 0;
}

@media (min-width:800px) {
  .conocenos{
    margin: 50px 0 70px 0;
    background-position-x:-23vw ;
    transition: var(--transitionCorta);
  }
  
  .subCard{
    margin: 0;
    height: 140px;
    display: flex;
    flex-wrap: wrap;
  }

  .flecha{
    align-self:flex-end;
    height: fit-content;
    align-items: center;
  }
  .flecha svg{
    top: 0;
  }
  .subCard:hover .flecha{
    gap: 5px;
  }
  .textRuta{
    margin: 0;
    white-space: nowrap;
  }
}
@media (min-width:1000px) {
  .conocenos{
    background-position-x:-15vw ;
  }
}
@media (min-width:1200px) {
  .conocenos{
    background-position-x:-7vw ;
  }
}
@media (min-width:1500px) {
  .conocenos{
    background-position-x:-3vw ;
  }
}