.container{
  display: grid;
  margin-top: 90px;
  width: 100%;

}
.links{
  cursor: pointer;

}
.links:hover{
  text-decoration: underline;
}
.top{
  display: flex;
  background-color: white;
  padding: 30px;
  gap: 20px;
  justify-content: center;
  margin-bottom: 40px;
}
.title{
  display: flex;
  font-size: 11pt;
  align-items: center;
}
.medium{
  padding: 0 20px 0 20px;
  display: grid;
  gap: 10px;
}
.subscription{
  font-size: 11pt;
}
.recibirMail{
  font-size: 9pt;
  font-family: 'Light';
} 
.bottom{
  display: grid;
  gap: 15px;
  padding: 20px ;
  overflow: hidden;
}
.copyright{
  justify-self: center;
  margin-bottom: 15px;
}
.Desktop{
  display: none;
}
.mobile{
  display: grid;
}
@media (min-width:800px) {
  .mobile{
    display: none;
  }
  .Desktop{
    display: grid;
  }
  .topDesktop{
    display: flex;
    align-items: center; 
    justify-content: center;
    margin: var(--marginDesktop);
    margin-bottom: 40px;
  }
  .inputMail{
    width: 35%;
    display:flex;
    padding: 0 10px 0 0;
  }
  .input{
    border: var(--rojo) 1px solid;
    background-color: var(--grisFondo);
    padding: 15px;
    outline: none;
    display: inline;
    width: 100%;
    height: 10px;
    margin-right: 5px;
  }
  .subscriptionDesktop{
    padding-right: 20px;
    display: flex;
    text-align: center;
  }
  .linksLogos{
    display: flex;
    gap: 20px;
    margin: 0 30px 0 30px;
  }
  .linkLogo{
    width: 30px;
  }
  .bottomDesktop{
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    justify-items: center;
    padding: 30px;  
    gap: 20px;
    background-color: white;
    margin: var(--marginDesktop);
  }
  .bottomFondo{
    background-color: white;
  }
  .left,.right,.center{
    display: grid;
    gap: 10px;
    height: fit-content;
    width: fit-content;
  }
  .bottomTitle{
    font-family: 'ExtraBold';
    margin-bottom: 20px;
    
  }
  .links{
    font-family: 'SemiBold';
  }
  .containers{
    display: grid;
  }
  .containerBottom{
    margin-top: 20px;
  }
  .copyrightDesktop{
    background-color: white;
    display: grid;
  }
  .copyright{
    font-family: 'Regular';
    font-size: 11pt;
  }
}