.container{
  margin: 20px;
  margin-top: 30px;
}
.principal{
  margin-bottom: 30px;
}
.header{
  display: grid;
  justify-items: left;
  gap: 10px;
  padding-left: 30px;
}
.title{
  color: var(--rojo);
  font-size: 23pt;
  text-align: left;
  width: 100%;
}
@media (min-width:337px) and (max-width:364px) {
  .title{
    font-size: 21pt;
  }
}
@media (min-width:295px) and (max-width:336px) {
  .title{
    font-size: 18pt;
  }
}
@media (min-width:3px) and (max-width:294px) {
  .title{
    font-size: 16pt;
  }
}
.fondo{background-color: white}

.subTitle{
  font-family: 'Bold';
  font-size: 14pt;
  text-align: left;
}
.subTitleBajo,.subTitleGris{
  background-color: white;
  padding-left: 23px;
  color: var(--rojo);
  font-family: 'Bold';
  font-size: 14pt;
}
.subTitleGris{
  color: var(--grisFuente);
  font-size: 13pt;
}
.cuerpoMobile{
  margin: 20px;
  height: calc(100% - 20px);
}
.img{
  width: 100%;
  filter: var(--shadow);
  position: relative;
  margin-bottom: 20px;
}
.items{
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  gap: 20px;
  background-color: white;
  margin-top: 20px;
}
.fondoImagen{
  height: 0;
}
.fondoImagen1{
  z-index: -1;
  height: 170px;
  width: 100%;
  position: relative;
  top: -170px;
  background-color: white;
}
  
.referencia{
  transition: 1s;
  overflow: hidden;
}
.segundaParte{
  background-color: white;
}
.verMas{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 10%;
  background-color: white;
  padding-bottom: 15px;
  font-size: 11pt;
}
.items li{
  font-family: 'SemiBold';
  list-style: none;
}
.li{
  display: flex; 
  gap: 5px;
  height: fit-content;
  align-items:0;
  width: 100%;
}
.li svg, .li path{
  font-size: 10px;
}
.titles{
  display: grid;
  gap: 5px;
}
.cuerpoDesktop{
  display: none;
}
.ocultoLeft{
  position: relative;
  opacity: 0;
  transition: var(--transitionCorta);
  left:-100px;
}
.ocultoRight{
  position: relative;
  opacity: 0;
  transition: var(--transitionCorta);
  right: -100px ;
}
.visibleRight{
  position: relative;
  opacity: 1;
  right: 0;
  transition: var(--transitionCorta);
}
.visibleLeft{
  position: relative;
  opacity: 1;
  left: 0px;
  transition: var(--transitionCorta);
}
@media (min-width:800px) {
  .cuerpoMobile{
    display: none;
  }
  .izquierdaDesktop{
    margin: var(--marginIzquierda);
  }
  .derechaDesktop{
    margin: var(--marginDerecha);
  }
  .cuerpoDesktop{
    display: block;
    margin-top: 50px;
  }
  .itemsDesktop{
    padding: 20px;
    display: grid;
    gap: 30px;
    align-items: stretch;
    background-color: white;
    padding-bottom: 20px;
    padding: 30px;
  }
  .liContainerDesktop{
    height: 100%;
    background-color: white;
  }
  .headerDesktop{
    width: 75%;
    display: grid;
    align-content: center;
    background-color: white;
    position: relative;
    left: 25%;
    padding-bottom: 20px;
  }
  .imgDesktop{
    right: 33.33%;
    width: 133%;
    filter: var(--shadow);
    position: relative;
  }
  .visibleDesktop{
    display: grid;
    grid-template-columns: 40% 60%;
    /* width: fit-content; */

  }
  .header{
    padding-left: 0;
  }
  .referenciaDesktop{
    width: 90%;
    position: relative;
    left: 10%;
    transition: 1s;
    overflow: hidden;
  }
  .verMas{
    position: relative;
    width: 90%;
    left: 10%;
  }
  .title{
    font-size: 42px;
  }
  .subTitle{
    font-size: 24px;
  }
  .li{
    font-family: 'SemiBold';
    list-style: none;
    font-size: 14pt;
  }
  .subTitleBajo{
    font-size: 18pt;
  }
  .subTitleGris{
    font-size: 16pt;
  }

}
