.container{
    display: grid;
    height: 100vh;
    width: 100vw;
    justify-items: center;
    align-content: center;
}

.loader circle, .loader svg{
    color: var(--rojo);
    font-size: 20pt;
}