.container{
  width: fit-content;
  display: grid;
  justify-items: center;
  gap: 30px;
  padding: 0 2em 0 2em;
  user-select: none;
}
.link{
  user-select: none;
}
.img{
  width: 300px;
  filter: var(--shadow);
}
.titles{
  display: grid;
  gap: 5px;
  justify-items: center;
}
.title{
  font-family: 'Bold';
}
.subTitle{
  font-family: 'ExtraLight';
}
